*{
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: 'Encode Sans Expanded', sans-serif;
}

ul {
  list-style-type: none;
  padding: 0 !important;
}
::-webkit-scrollbar {
  width: 2px;
}

section {
  padding: 120px 0 0 0;
  overflow: hidden;
}

.section-title {
  text-align: center;
  padding-bottom: 30px;
}

.section-title h2 {
  font-size: 32px;
  font-weight: bold;
  text-transform: uppercase;
  padding-bottom: 10px;
  margin-bottom: 0;
  position: relative;
  color:  rgb(242, 242, 242);
}

.section-line {
  width: 50px;
  height: 4px;
  background: linear-gradient(270deg,
  rgb(19, 173, 199) 0%,
  rgb(148, 93, 214) 100%);
  margin: 0 auto;
  margin-bottom: 30px;
}

.footer-line {
  width: 50%;
  height: 1px;
 
  
  background: linear-gradient(270deg,
  rgb(19, 173, 199) 0%,
  rgb(148, 93, 214) 100%);
  margin: 0 auto;
 
 
}
.section-title p {
  margin-bottom: 0;
}

/* Scroll bar */
::-webkit-scrollbar {
  width: 2px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}
